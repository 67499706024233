import Vue from 'vue';
import App from './App.vue';
import router from './router';
Vue.prototype.Vue = Vue;
import { Toast } from 'vant';
import "vant/lib/index.css";
Vue.prototype.Toast = Toast;
import { Base64 } from "js-base64";
Vue.prototype.Base64 = Base64;
import * as echart from "echarts";
Vue.prototype.echart = echart;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
