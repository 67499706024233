<template>
    <div>
        <div v-if="evaluationObj && JSON.stringify(evaluationObj) !== '{}'"  class="family">
            <div class="head">
                <img src="@/assets/images/family/head.png" />
            </div>
            <div class="title">
                <div class="title-left">
                    <div class="title-up">
                        <img src="@/assets/images/family/title-icon.png" />
                        <div>Basic Information</div>
                    </div>
                    <div class="title-down">基本信息</div>
                </div>
                <img class="title-right" src="@/assets/images/family/title-right.png" />
            </div>
            <div class="card card1">
                <div class="card-box">
                    <div class="info">
                        <div>学校：{{dataObj.schoolName}}</div>
                        <div>年级：{{dataObj.gradeName}}</div>
                        <div>班级：{{dataObj.className}}</div>
                        <div>人数：{{dataObj.studentCount}}人</div>
                    </div>
                </div>
            </div>
            <div class="title">
                <div class="title-left">
                    <div class="title-up">
                        <img src="@/assets/images/family/title-icon.png" />
                        <div>Test Result</div>
                    </div>
                    <div class="title-down">测试结果</div>
                </div>
                <img class="title-right" src="@/assets/images/family/title-right.png" />
            </div>
            <div v-for="item,index in dataObj.chekItemDatas" class="card card2">
                <div class="card-box">
                    <div class="card-title">
                        <img :src="require('@/assets/images/family/title-'+ index +'.png')" />
                    </div>
                    <div class="card-content1">
                        <div class="card-content1-left" :id="'myGaugeChart'+ item.itemId"></div>
                        <div class="card-content1-right">
                            <div>{{resultListTips[index]}}：{{item.thisAvgCheckData}}{{item.unit}}</div>
                            <div>已测人数：<span class="color3">{{item.thisCheckStudentCount}}</span>/{{dataObj.studentCount}}人</div>
                            <button @click="toDetails(item)">查看名单</button>
                        </div>
                    </div>
                    <div class="card-content2">
                        <div class="card-content2-title">
                            <div></div>
                            <div>{{item.itemName}}与上次对比</div>
                        </div>
                        <div class="card-content2-table">
                            <div class="card-content2-tr">
                                <div class="card-content2-th" v-for="sitem in item.physicalLevelName">
                                    {{sitem}}
                                </div>
                            </div>
                            <div class="card-content2-tr" v-for="sitem in item.itemDatas">
                                <div class="card-content2-td">{{sitem.checkIndex}}</div>
                                <div v-for="xsitem in sitem.classPhysicalLevels" class="card-content2-td">
                                    {{xsitem.studentCountValue}}人
                                    <span class="color2" v-if="xsitem.levelStatus === -1">↓</span>
                                    <span class="color1" v-if="xsitem.levelStatus === 1">↑</span>
                                </div>
                                <div class="card-content2-td">{{sitem.passRateValue}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="card-content3">
                        <div class="card-content3-box">
                            <div class="card-content3-title">
                                <div></div>
                                <div>对比结果</div>
                            </div>
                            <div class="card-content3-content">{{item.contrastResult}}</div>
                        </div>
                        <div class="card-content3-box">
                            <div class="card-content3-title">
                                <div></div>
                                <div>评语</div>
                            </div>
                            <div class="card-content3-content">{{item.thisComment}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="title">
                <div class="title-left">
                    <div class="title-up">
                        <img src="@/assets/images/family/title-icon.png" />
                        <div>Comprehensive Evaluation</div>
                    </div>
                    <div class="title-down">综合评估</div>
                </div>
                <img class="title-right" src="@/assets/images/family/title-right.png" />
            </div>
            <div class="card">
                <div class="card-box">
                    <div class="evaluation2">
                        <div class="evaluation2-echarts" id="myRadarChart"></div>
                        <div class="echarts-item" v-for="item,index in evaluationObj.itemInfoDatas" :class="'echarts-item'+ index">
                            <div class="echarts-info">
                                <div>{{ item.itemNameDescribe }}</div>
                                <div>{{ item.thisItemDateLevel }}</div>
                                <div v-if="item.itemDataStatus === -1">-{{ item.itemScoreDiffValue }}<span class="color2">↓</span></div>
                                <div v-if="item.itemDataStatus === 1">+{{ item.itemScoreDiffValue }}<span class="color1">↑</span></div>
                            </div>
                            <div class="echarts-icon">
                                <img :src="require('@/assets/images/family/icon-'+ index +'.png')" />
                                <div>{{ item.itemName }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="card-content3-box">
                        <div class="card-content3-title">
                            <div></div>
                            <div>结论</div>
                        </div>
                        <div v-html="evaluationObj.conclusion" class="card-content3-content"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="noData" class="no-data">
            <img :src="require('@/assets/images/family/no-data.png')" />
            <div>还有项目未测试完成，无法生成体测报告~</div>
        </div>
    </div>
</template>
<script>
    import { getTeacherReportData } from '@/utils/api';

  export default {
    data() {
        return {
            classId: null,
            noData: false,
            dataObj: {},
            evaluationObj: {},
            resultListTips: ['本次平均身高', '本次平均体重', '本次平均成绩', '本次平均成绩', '本次平均成绩', '本次平均成绩', '本次平均成绩', '本次平均成绩']
        }
    },
    created() {
        document.title = '体质报告';
    },
    mounted() {
        // let type = 'ml';
        // let token = 'eyJhbGciOiJIUzUxMiJ9.eyJleHAiOjE2ODEzNjYwNDMsInN1YiI6IntcInJvbGVUeXBlXCI6XCIzXCIsXCJwaG9uZVwiOlwiMTUxMTY0MDYyMTNcIixcImlkXCI6MTAzOTN9IiwiaWF0IjoxNjgwNzYxMjQzfQ.4jEZyOXqNbjn334JqlcLXJGRushweK4_A2xb9X4mt7bZZr2_n7-YG_JTubd2i-RvBVCoecUhLR3u9-cD7LHRWQ';
        // let classId = '10053'
        // console.log(this.Base64.encode(type + '$' + token+'$'+classId))
        if(this.$route.query.params) {
            let temp = this.Base64.decode(this.$route.query.params).replace(/\$/g, " ").split(' ');
            if(temp[0] === 'ml') {
                let token = temp[1];
                let classId = temp[2];
                this.classId = classId;
                sessionStorage.setItem('token', token);
                getTeacherReportData(classId).then(res => {
                    this.dataObj = res.data.data;
                    this.evaluationObj = this.dataObj.physicalItemSynthesize;
                    if(this.evaluationObj && JSON.stringify(this.evaluationObj) !== '{}') {
                        this.dataObj.chekItemDatas.forEach(item => {
                            this.Vue.nextTick(() => {
                                this.getGaugeChart(item.itemId, item.thisTestRank, item.thisTestRankValue);
                            });
                        })
                        this.Vue.nextTick(() => {
                            this.getRadarChart();
                        })
                    }else {
                        this.noData = true;
                    }
                });
            }else {
                this.Toast.fail('非法调用');
            }
        }
    },
    methods: {
        toDetails(item) {
            this.$router.push({
            path: '/teacherReportDetail',
            query: {
                classId: this.classId,
                itemId: item.itemId,
                itemName: item.itemName,
                planId: item.thisPlanId,
            }
          });
        },
        getGaugeChart(id, thisTestRank, thisTestRankValue){
            let myChart = this.echart.init(document.getElementById('myGaugeChart'+id));
            let option = {
                series: [
                    {
                        type: 'gauge',
                        radius: '100%',
                        center: ['50%', '50%'],
                        min: 0,
                        max: 5,
                        splitNumber: 5,
                        itemStyle: {
                            color: '#00D19C'
                        },
                        progress: {
                            show: true,
                            width: 4
                        },
                        pointer: {
                            show: false,
                            // length: '55%',
                            // width: 4
                        },
                        axisLine: {
                            lineStyle: {
                                width: 4
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            // show: false,
                            distance: 4,
                            length: 4,
                            lineStyle: {
                                width: 1,
                                color: '#999999'
                            }
                        },
                        axisLabel: {
                            show: false,
                            // distance: 4,
                            // length: 4,
                            // lineStyle: {
                            //     width: 1,
                            //     color: '#999999'
                            // }
                        },
                        detail: {
                            valueAnimation: true,
                            offsetCenter: [0, '10%'],
                            fontSize: 16,
                            fontWeight: 'bolder',
                            formatter: thisTestRankValue,
                            color: '#111111',
                            offsetCenter: [0, 0]
                        },
                        data: [
                            {
                                value: thisTestRank
                            }
                        ]
                    },
                ]
            };
            myChart.setOption(option);
        },
        getRadarChart(){
            let myChart = this.echart.init(document.getElementById('myRadarChart'));
            let option = {
                legend: {
                    data: ['本次结果', '上次结果'],
                    itemWidth: 8,
                    itemHeight: 8,
                    textStyle: {
                        color: '#9296AF'
                    }
                },
                radar: {
                    startAngle: 60,
					indicator: [
						{name: '', max: 5},
						{name: '', max: 5},
						{name: '', max: 5},
						{name: '', max: 5},
						{name: '', max: 5},
						{name: '', max: 5},
					],
                    radius: 59.5
                },
                series: [
                    {
                    type: 'radar',
                    data: [
                        {
                            value: this.evaluationObj.preScores,
                            name: '上次结果',
                            itemStyle: {
                                color: '#23A946'
                            }
                        },
                        {
                            value: this.evaluationObj.thisScores,
                            name: '本次结果',
                            itemStyle: {
                                color: '#FE4B4B'
                            }
                        }
                    ]
                    }
                ]
            };
            myChart.setOption(option);
        }
    }
  }
</script>
<style scoped>
    .family {
        width: 100vw;
        height: 100%;
        padding: 3.375rem 0rem 0.875rem;
        background-image: url('@/assets/images/family/bg.png');
        background-size: 100% 100%;
    }
    .head {
        width: 21.1875rem;
        height: 5.625rem;
        text-align: center;
        margin: 0rem auto;
    }
    .head img {
        width: 100%;
        height: 100%;
    }
    .title {
        margin: 1.75rem auto 0.5rem;
        width: 21.6875rem;
        padding: 0rem 0.5rem;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .title-left {}
    .title-up {
        display: flex;
        align-items: center;
    }
    .title-up img {
        width: 1.1875rem;
        height: 1.1875rem;
        margin-right: 0.1875rem;
    }
    .title-up div {
        font-size: 0.75rem;
        font-weight: 400;
        color: #38EFFF;
        line-height: 0.75rem;
        text-shadow: 0rem 0.125rem 0.21875rem rgba(2,60,190,0.48);
    }
    .title-down {
        margin-top: 0.625rem;
        font-size: 1.625rem;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 1.625rem;
        text-shadow: 0rem 0.125rem 0.1875rem rgba(2,60,190,0.48);
    }
    .title-right {
        width: 4rem;
        height: 4rem;
    }
    .card {
        width: 21.6875rem;
        padding: 0.5rem;
        box-sizing: border-box;
        background: #3C70F6;
        box-shadow: 0rem 0rem 0.0625rem 0.03125rem rgba(255,255,255,0.39);
        border-radius: 0.75rem;
        margin: 0rem auto;
    }
    .card-box {
        width: 20.75rem;
        padding: 0.9375rem;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0rem 0.09375rem 0.21875rem 0rem rgba(255,255,255,0.35);
        border-radius: 0.5rem;
    }
    .card-title {
        height: 2.875rem;
        text-align: center;
    }
    .card-title img {
        height: 100%;
        object-fit: cover;
    }
    .card-content1 {
        margin-top: 0.875rem;
        display: flex;
    }
    .card-content1-left {
        width: 7.3125rem;
        height: 5.9375rem;
        margin-right: 0.625rem;
    }
    .card-content1-right {
        padding: 0.9375rem 0rem 0.0rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .card-content1-right div {
        font-size: 0.9375rem;
        font-weight: 500;
        color: #333333;
        line-height: 0.9375rem;
    }
    .card-content1-right button {
        width: fit-content;
        padding: 0.5rem 0.625rem;
        font-size: 0.9375rem;
        font-weight: 500;
        color: #FFFFFF;
        background: #408EF7;
        border-radius: 0.3125rem;
        border: none;
    }
    .card-content2 {
        margin-top: 1.25rem;
    }
    .card-content2-title {
        display: flex;
        align-items: center;
    }
    .card-content2-title div:nth-child(1){
        margin-right: 0.4375rem;
        width: 0.15625rem;
        height: 0.875rem;
        background: #408EF7;
        border-radius: 0.09675rem;
    }
    .card-content2-title div:nth-child(2){
        font-size: 0.9375rem;
        font-weight: 500;
        color: #111111;
    }
    .card-content2-table {
        margin-top: 0.75rem;
        border-top: 1px solid #D3D3D4;
        border-left: 1px solid #D3D3D4;
    }
    .card-content2-tr {
        display: flex;
        border-bottom: 1px solid #D3D3D4;
    }
    .card-content2-th {
        flex: 1;
        text-align: center;
        height: 2.3125rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #D3D3D4;
        font-size: 0.625rem;
        font-weight: 500;
        color: #111111;
    }
    .card-content2-td {
        flex: 1;
        text-align: center;
        height: 2.3125rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #D3D3D4;
        font-size: 0.625rem;
        font-weight: 500;
        color: #111111;
    }
    .card-content2-td:last-child, .card-content2-th:last-child {
        flex: 1.2;
    }
    .card-content3 {
        margin-top: 0.9375rem;
    }
    .card-content3-box {
        padding: 0.875rem;
        background: #F8F8F8;
        border-radius: 0.25rem;
    }
    .card-content3-box ~ .card-content3-box {
        border-top: 1px solid #ECEDF2;
    }
    .card-content3-title {
        display: flex;
        align-items: center;
    }
    .card-content3-title div:nth-child(1){
        width: 0.4375rem;
        height: 0.4375rem;
        background: #FCA938;
        transform: rotate(45deg);
        margin-right: 0.25rem;
    }
    .card-content3-title div:nth-child(2) {
        font-size: 0.9375rem;
        font-weight: 500;
        color: #111111;
    }
    .card-content3-content {
        margin-top: 0.75rem;
        font-size: 0.8125rem;
        font-weight: 500;
        color: #333333;
        line-height: 1.125rem;
    }
    .card1 {
        margin-bottom: 2.3125rem;
    }
    .card2 {
        margin-bottom: 1.40625rem;
    }
    .info {
        font-size: 0.9375rem;
        font-weight: 500;
        color: #111111;
        line-height: 1.6875rem;
    }
    .color1 {
        color: #23A946;
    }
    .color2 {
        color: #FE4B4B;
    }
    .color3 {
        color: #408EF7;
    }
    .evaluation2 {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .evaluation2-echarts {
        padding: 0.3125rem;
        box-sizing: border-box;
        width: 100%;
        height: 26rem;
    }
    .echarts-item {
        position: absolute;
        /* top: 50%; */
        /* left: 50%; */
        /* transform: translate(-50%, -50%); */
    }
    .echarts-info {
        width: 4.375rem;
        height: 3.25rem;
        padding: 0.3125rem;
        box-sizing: border-box;
        background: #F0F8FE;
        border-radius: 0.0625rem;
    }
    .echarts-info div:nth-child(1) {
        font-size: 0.625rem;
        font-weight: 400;
        color: #111111;
    }
    .echarts-info div:nth-child(2) {
        font-size: 0.6875rem;
        font-weight: 400;
        color: #111111;
    }
    .echarts-info div:nth-child(3) {
        font-size: 0.625rem;
        font-weight: 400;
        color: #666666;
    }
    .echarts-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .echarts-icon img {
        width: 1.4375rem;
        height: 1.4375rem;
    }
    .echarts-icon div {
        color: #333333;
        font-size: 0.625rem;
        font-weight: 500;
    }
    .echarts-item0 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        transform: translate(70%, -120%);
    }
    .echarts-item0 .echarts-info {
        margin-bottom: 0.25rem;
    }
    .echarts-item1 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        transform: translate(-70%, -120%);
    }
    .echarts-item1 .echarts-info {
        margin-bottom: 0.25rem;
        text-align: right;
    }
    .echarts-item2 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        transform: translate(-150%, 0%);
    }
    .echarts-item2 .echarts-info {
        order: 1;
        margin-top: 0.25rem;
        text-align: right;
    }
    .echarts-item3 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        transform: translate(-70%, 120%);
    }
    .echarts-item3 .echarts-info {
        order: 1;
        margin-top: 0.25rem;
        text-align: right;
    }
    .echarts-item4 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        transform: translate(70%, 120%);
    }
    .echarts-item4 .echarts-info {
        order: 1;
        margin-top: 0.25rem;
    }
    .echarts-item5 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        transform: translate(150%, 0%);
    }
    .echarts-item5 .echarts-info {
        order: 1;
        margin-top: 0.25rem;
    }
    .no-data {
        width: 100vw;
        height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .no-data img {
        width: 11.3125rem;
        height: 11.3125rem;
    }
    .no-data div {
        margin-top: 1.4375rem;
        font-size: 0.9375rem;
        font-weight: 500;
        color: #666666;
    }
</style>