<template>
    <div class="detail">
        <div class="detail-date">{{date}}</div>
        <div class="detail-title">
            <div>姓名</div>
            <div>参考值</div>
            <div>成绩</div>
            <div>结果</div>
        </div>
        <div v-for="item in recorde" class="detail-recorde">
            <div>{{item.studentName}}</div>
            <div>{{item.referenceValue}}</div>
            <div>{{item.checkData}}</div>
            <div>{{item.result}}</div>
        </div>
    </div>
</template>
<script>
import { getTeacherReportDetails } from '@/utils/api';
    export default {
        data() {
            return {
                date: '',
                recorde: []
            }
        },
        created() {
            let classId = this.$route.query.classId;
            let itemId = this.$route.query.itemId;
            let planId = this.$route.query.planId;
            document.title = this.$route.query.itemName+'测试名单';
            getTeacherReportDetails(classId, itemId, planId).then(res => {
                let result = res.data.data;
                this.date = result.checkDate;
                this.recorde = result.studentDatas;
            })
        }
    }
</script>
<style scoped>
    .detail {
        width: 100vw;
        background-color: #F2F2F2;
    }
    .detail-date {
        width: 100%;
        text-align: center;
        height: 2.6875rem;
        line-height: 2.6875rem;
        font-size: 0.9375rem;
        font-weight: 500;
        color: #111111;
        background-color: #FFFFFF;
    }
    .detail-title {
        margin-top: 0.5rem;
        width: 100%;
        height: 2.75rem;
        background-color: #FFFFFF;
        display: flex;
    }
    .detail-title div {
        flex: 1;
        width: 100%;
        text-align: center;
        height: 2.75rem;
        line-height: 2.75rem;
        font-size: 0.9375rem;
        font-weight: 500;
        color: #333333;
    }
    .detail-recorde {
        width: 100%;
        height: 3.4375rem;
        background-color: #FFFFFF;
        border-top: 1px solid #EEEEEE;
        display: flex;
    }
    .detail-recorde div {
        flex: 1;
        width: 100%;
        text-align: center;
        height: 3.4375rem;
        /* line-height: 3.4375rem; */
        font-size: 0.9375rem;
        font-weight: 500;
        color: #111111;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>