import request from '@/utils/request'

export function getFamilyReportData1(studentId) {
    return request({
        url: `/api/app/v2/share/physical/findOnePhysicalComprReport/${studentId}`,
        method: 'GET'
    })
}
export function getFamilyReportData(studentId) {
    return request({
        url: `/api/schoolTestRecord/findOnePhysicalComprReport/${studentId}`,
        method: 'GET'
    })
}
export function getTeacherReportData(classId) {
    return request({
        url: `/api/schoolTestRecord/findOneClassPhysicalComprReport/${classId}`,
        method: 'GET'
    })
}
export function getTeacherReportDetails(classId, itemId, planId) {
    return request({
        url: `/api/schoolTestRecord/findOneClassPhysicalReportInfo`,
        method: 'POST',
        data: {
            classId,
            itemId,
            planId
        }
    })
}