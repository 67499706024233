<template>
	<div style="max-width: 750px; font-size: 0;margin: 0 auto;" id="editerHtml">
	    <div style="display: none;padding-bottom: 2rem;" id="pram01">
	        <div class="desc_img"><img src="@/assets/images/family/wxShareTips.jpg" class="list-img"/></div>
	    </div>
	</div>
</template>

<script>
	export default {
		mounted() {
			/*浏览器版本信息*/
			var browser = {
					versions: function() {
					  var u = navigator.userAgent, app = navigator.appVersion;
					  return {//移动终端浏览器版本信息
						wx: u.indexOf('MicroMessenger') > -1,//是否是wx
						trident: u.indexOf('Trident') > -1, // IE
						presto: u.indexOf('Presto') > -1, // opera
						webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌
						gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, // 火狐
						mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), // 是否为移动终端
						ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
						android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或者uc浏览器
						iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, // 是否为iPhone或者QQHD浏览器
						iPad: u.indexOf('iPad') > -1, // 是否iPad
						webApp: u.indexOf('Safari') == -1 // 是否web应该程序，没有头部与底部
					  };
					}(),
					language: (navigator.browserLanguage || navigator.language).toLowerCase()
			}
				 
			if (browser.versions.ios || browser.versions.iPhone) {
			  window.location.href="https://apps.apple.com/cn/app/%E6%96%B0%E9%A9%AC%E8%89%AF/id1377995945"; //ios版本的下载地址
			} else if (browser.versions.android) {
					if(browser.versions.wx){
						document.getElementById("pram01").style.display="block";
					}else{
				  window.location.href="newmaliang://com.mledu.newmaliang"; //打开apk
				  setTimeout(() => {
					  window.location.href="https://a.app.qq.com/o/simple.jsp?pkgname=com.mledu.newmaliang&g_f=1000047"; // android版本的下载地址
				  }, 500);   
					}
			}
		}
	}
</script>

<style scoped>
	img {
		width: 100%;
		height: 100%;
	}
</style>