import Vue from 'vue'
import VueRouter from 'vue-router'
import FamilyReport from '../views/FamilyReport.vue'
import TeacherReport from '../views/TeacherReport.vue'
import TeacherReportDetail from '../views/TeacherReportDetail.vue'
import DownloadHY from '../views/DownloadHY.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/FamilyReport',
    name: 'familyReport',
    component: FamilyReport
  },
  {
    path: '/TeacherReport',
    name: 'teacherReport',
    component: TeacherReport
  },
  {
    path: '/TeacherReportDetail',
    name: 'teacherReportDetail',
    component: TeacherReportDetail
  },
  {
    path: '/DownloadHY',
    name: 'downloadHY',
    component: DownloadHY
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
